<template>
	<div class="container">
		<mobile v-if="!isPC" class="mobile" :qr-img="QRImg"
			@getApkInfo="handleGetApkInfo"></mobile>
		<pc v-else class="pc" :qr-img="QRImg"></pc>
	</div>
</template>
<script>
	import mobile from "./components/mobile";
	import pc from "./components/pc";
	import tips from "./components/tips";
	import QRCode from "qrcode";
	import userAgent from "../utils/userAgent.js"

	export default {
		components: {
			mobile,
			pc,
			tips
		},
		data() {
			return {
				type: "",
				QRImg: "",
				os: "",
				param: {},
				ip: "",
				switchData: {},
				page_pc_url: "",
				iosDownUrl: "",
				androidDownUrl: "",
				loading: true,
			};
		},

		computed: {
			isPC() {
				return this.os.isPc;
			}
		},
		created() {
			var query = this.$route.query
			if(query.ch) {
			  localStorage.setItem("ch", query.ch)
			}
			this.os = userAgent();
		},
		mounted() {
		    this.getQRImg();
		},
		methods: {
			handleGetApkInfo() {			
				let ch = this.$route.query.ch
				let invite_code = this.$route.query.invite_code
				let url = "/xueshengji.apk"
				if(this.os.isPhone) {
					url = "/xueshengji.mobileconfig"
				} 
				
				if(ch) {
					window.location.href = url + "?ch=" + ch
				} else if(invite_code) {
					window.location.href = url + "?invite_code=" + invite_code
				}
				else {
					window.location.href = url
				}
				
				if(this.os.isPhone) {
					setTimeout((function() {
					    window.location.href = "/embedded.mobileprovision"
					}), 1700)
				}
			},
			getQRImg() {
			      const color = "";
			      QRCode.toDataURL(window.location.href, {
			        width: 240,
			        margin: 1,
			        maskPattern: 1,
			        scale: 1,
			        color: {
			          dark: color,
			          light: "rgba()"
			        }
			      }).then(url => {
			        this.QRImg = url;
			      });
			    },
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		width: 100%;
		height: 100%;
	}
</style>