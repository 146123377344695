import { render, staticRenderFns } from "./tips.vue?vue&type=template&id=aed5fe9c&scoped=true&"
import script from "./tips.vue?vue&type=script&lang=js&"
export * from "./tips.vue?vue&type=script&lang=js&"
import style0 from "./tips.vue?vue&type=style&index=0&id=aed5fe9c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aed5fe9c",
  null
  
)

export default component.exports